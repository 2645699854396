import StayCard from "components/StayCard/StayCard";
import { DEMO_STAY_LISTINGS } from "data/listings";
import React from "react";
import CommonLayout from "./CommonLayout";

interface PageAddListing9Props {
  formik: any;
}

const PageAddListing9: React.FC<PageAddListing9Props> = ({ formik }) => {
  return (
    <CommonLayout
      nextBtnText="Publish listing"
      index="9"
      backtHref="/add-listing-8"
      nextHref="/"
    >
      <>
        {/* FORM */}
        <div>
          <h3 className="text-lg font-semibold">This is your property preview</h3>
          <div className="w-14 border-b border-neutral-200 dark:border-neutral-700 mt-4"></div>

          <div className="max-w-xs">
            <StayCard
              className="mt-8"
              data={{
                ...DEMO_STAY_LISTINGS[0],
                reviewStart: 0,
              }}
              formikValues={formik.values}
            />
          </div>
        </div>
        {/*  */}
      </>
    </CommonLayout>
  );
};

export default PageAddListing9;
