import * as React from "react";
import Box from "@mui/material/Box";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import { useFormik } from "formik";
import * as Yup from "yup";
import { API_URL } from "../../api/config";
import axios from "axios";
import { toast } from "react-toastify";

import PageAddListing1 from "./PageAddListing1";
import PageAddListing2 from "./PageAddListing2";
import PageAddListing3 from "./PageAddListing3";
import PageAddListing4 from "./PageAddListing4";
import PageAddListing5 from "./PageAddListing5";
import PageAddListing6 from "./PageAddListing6";
import PageAddListing7 from "./PageAddListing7";
import PageAddListing8 from "./PageAddListing8";
import PageAddListing9 from "./PageAddListing9";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const HorizontalLinearStepper: React.FC = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [isLoading, setisLoading] = useState(false);
  const [isOtpSent, setIsOtpSent] = useState(false);


  const checkWordArray = async (newStr: any) => {
    const numbers = [
      "zero",
      "one",
      "two",
      "three",
      "four",
      "five",
      "six",
      "seven",
      "eight",
      "nine",
      "ten",
    ];
    numbers.map((number, index) => {
      newStr = newStr.replace(new RegExp(number, "ig"), `${index}`);
    });
    let digits = newStr.match(/\d/g);
    let result = [];
    if (digits) {
      let str = "";
      for (let i = 0; i < digits.length; i++) {
        let diff =
          i > 0
            ? Math.abs(
                newStr.indexOf(digits[i]) - newStr.indexOf(digits[i - 1])
              )
            : 0;
        newStr = newStr.substring(newStr.indexOf(digits[i - 1]));
        if (diff < 10) {
          str += digits[i];
        } else {
          result.push(str);
          str = digits[i];
        }
        if (i + 1 === digits.length) {
          result.push(str);
        }
      }
    }
    let numberPresent = false;
    result.length > 0 &&
      result.map((item) => {
        const isValidIndianPhoneNumber = /^(?:\+?91|0)?[7-9]\d{9}$/.test(item);
        if (isValidIndianPhoneNumber) {
          numberPresent = true;
          formik.setFieldError(
            "place_descriptions",
            "Please don't include phone numbers in the description, Kindly review your description and try again."
          );
        }
      });
    return numberPresent;
  };

  const handleNext = async () => {
    if (activeStep === 5) {
      if (formik.values.place_descriptions) {
        const isPresent = await checkWordArray(
          formik.values.place_descriptions
        );
        if (isPresent) {
          return;
        } else {
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
      } else {
        formik.setFieldError(
          "place_descriptions",
          "place description should not be empty."
        );
        return;
      }
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      return;
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const pageValidation1 = Yup.object({
    type: Yup.string().required("Property type is required"),
    propertyPhoneNumber: Yup.string()
      .matches(/^\d+$/, "Phone number can only contain numbers")
      .min(10, "Phone number must be at least 10 digits")
      .max(15, "Phone number must be no more than 15 digits"),
    // otp: Yup.number()
    // .typeError("OTP must be a number")
    // .min(100000, "OTP must be a 6-digit number")
    // .max(999999, "OTP must be a 6-digit number")
  });
  const pageValidation2 = Yup.object({
    category: Yup.string().required("Category is required"),
    landmark: Yup.string().required("Landmark is required"),
    type: Yup.string().required("Type of place is required"),
    Address: Yup.string()
      .required("Address is required")
      .min(10, "Address must be at least 10 characters long")
      .max(100, "Address cannot exceed 100 characters"),
    nearest_airport: Yup.string(),
  });
  const pageValidation3 = Yup.object({
    guests: Yup.number().required("Number of guests is required"),
    rooms: Yup.number().required("Number of rooms is required"),
    beds: Yup.number().required("Number of beds is required"),
    bathrooms: Yup.number().required("Number of bathrooms is required"),
  });
  // Page 4
  const pageValidation4 = Yup.object({
    // amenities: Yup.string().required("Amenities information is required"),
  });
  const pageValidation5 = Yup.object({
    cover_image: Yup.string().required("Cover image is required"),
    galleryImgs: Yup.array()
      .of(Yup.string())
      .required("Gallery images are required"),
  });
  // Page 6
  const pageValidation6 = Yup.object({
    place_descriptions: Yup.string().required("Place description is required"),
  });
  // Page 7
  const pageValidation7 = Yup.object({
    propertyRules: Yup.string().required("Property rules is required"),
  });
  // Page 8
  const pageValidation8 = Yup.object({
    price: Yup.number()
      .typeError("Price must be number")
      .min(0, "Price must be greater than zero")
      .required("Price is required"),
  });

  const pageValidation9 = Yup.object({
    // type: Yup.string().required("Property type is required"),
    // title: Yup.string().required("Place name is required"),
  });
  const token = localStorage.getItem("token");
  const pathname = window.location.pathname;
  const id = pathname.split("/").pop();

  const getOnePropertyDetails = async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(
        `${API_URL}/property/get-property-details?id=${id}`,
        {
          headers: {
            token: token,
          },
        }
      );
      if (response.data.error === false) {
        const propertyData = response.data.propertyDetails;
        formik.setValues(propertyData);
      }
    } catch (err) {
      console.error("error while fetching properties data", err);
    }
  };

  React.useEffect(() => {
    getOnePropertyDetails();
  }, []);
  const formik = useFormik({
    initialValues: {
      type: "",
      title: "",
      country: "India",
      area_name: "",
      floor: "",
      landmark: "",
      city: "",
      guests: 0,
      rooms: 0,
      beds: 0,
      bathrooms: 0,
      kitchen: 0,
      amenities: [],
      pet: false,
      couple_friendly: false,
      id_required: false,
      party_organizing: false,
      cooking: false,
      smoking: false,
      drinking: false,
      additional_rules: [],
      place_descriptions: "",
      cover_image: "",
      galleryImgs: [],
      price: "",
      propertyRules: "",
      propertyHighlights: [],
      typeOfLocation: [],
      Address: "",
      propertyPhoneNumber:"",
    },
    validationSchema: () => {
      switch (activeStep) {
        case 0:
          return pageValidation1;
        case 1:
          return pageValidation2;
        case 2:
          return pageValidation3;
        case 4:
          return pageValidation5;

        case 5:
          return pageValidation6;
        case 6:
          return pageValidation7;
        case 7:
          return pageValidation8;
        case 8:
          return pageValidation9;

        default:
          return Yup.object({});
      }
    },
    onSubmit: (values) => {
      handleNext();
    },
  }) as any;

  const navigate = useNavigate();
  const handleFormSubmission = async () => {
    setisLoading(true);
    try {
      await formik.validateForm();
      const response = await axios.post(
        `${API_URL}/property/update-property`,

        {
          ...formik.values,
          id: id,
        },
        {
          headers: {
            token: token,
          },
        }
      );
      const text = response.data.message;
      if (response.data.error === false) {
        toast.success(text);
      }
      if (response.data.error === true) {
        toast.error(text);
      }
    } catch (error) {
      console.error("Error during login:", error);
    }
    setisLoading(false);
    navigate("/property");
  };

  const components = [
    <PageAddListing1 formik={formik} setActiveStep={setActiveStep} setIsOtpSent={setIsOtpSent} isOtpSent={isOtpSent}/>,
    <PageAddListing2 formik={formik} />,
    <PageAddListing3 formik={formik} />,
    <PageAddListing4 formik={formik} />,
    <PageAddListing5 formik={formik} />,
    <PageAddListing6 formik={formik} />,
    <PageAddListing7 formik={formik} />,
    <PageAddListing8 formik={formik} />,
    <PageAddListing9 formik={formik} />,
  ];

  return (
    <Box sx={{ width: "100%" }}>
      <React.Fragment>
        {components[activeStep]}

        <div className="px-4 max-w-3xl mx-auto pb-12 pt-1 sm:py-12 lg:pb-12">
          <div className="flex justify-end space-x-5">
            <ButtonSecondary
              onClick={() => {
                handleBack();
                window.scrollTo({ top: 0, behavior: "smooth" });
              }}
              disabled={activeStep === 0}
            >
              Go back
            </ButtonSecondary>{" "}
            {activeStep === components.length - 1 ? (
              <ButtonPrimary
                type="button"
                disabled={isLoading}
                onClick={() => {
                  handleFormSubmission();
                  window.scrollTo({ top: 0, behavior: "smooth" });
                }}
              >
                Submit
              </ButtonPrimary>
            ) : (
              <ButtonPrimary
                type="button"
                onClick={() => {
                  formik.handleSubmit();
                  // Scroll to the top of the page
                  window.scrollTo({ top: 0, behavior: "smooth" });
                }}
              >
                Continue
              </ButtonPrimary>
            )}
          </div>
        </div>
      </React.Fragment>
    </Box>
  );
};

export default HorizontalLinearStepper;
