import React, { FC } from "react";
import GallerySlider from "components/GallerySlider/GallerySlider";
import { DEMO_STAY_LISTINGS } from "data/listings";
import { StayDataCard, StayDataType } from "data/types";
import StartRating from "components/StartRating/StartRating";
import { Link } from "react-router-dom";
import BtnLikeIcon from "components/BtnLikeIcon/BtnLikeIcon";
import SaleOffBadge from "components/SaleOffBadge/SaleOffBadge";
import Badge from "shared/Badge/Badge";

export interface StayCardProps {
  formikValues?: StayDataCard;
  className?: string;
  data?: StayDataType;
  size?: "default" | "small";
}

const DEMO_DATA = DEMO_STAY_LISTINGS[0];

const StayCard: FC<StayCardProps> = ({
  formikValues = {},
  size = "default",
  className = "",
  data = DEMO_DATA,
}) => {
  const {
    // listingCategory
    reviewStart,
    reviewCount,
    id,
  } = data;
  const {
    price,
    type,
    subtype,
    category,
    nearest_airport,
    Address,
    title,
    country,
    floor,
    area_name,
    city,
    landmark,
    beds,
    pet,

    cover_image,
    galleryImgs,
  } = formikValues as any;
  
  const address = `${floor ? `floor no-${floor},` : ""}  ${
    area_name ? `${area_name},` : ""
  } ${landmark ? `${landmark},` : ""} ${city ? `${city},` : ""} ${
    country ? `${country}` : ""
  } ${" "}`;

  const renderSliderGallery = () => {
    const combinedArray = [cover_image || "", ...(galleryImgs || [])];

    return (
      <div className="relative w-full">
        <GallerySlider
          uniqueID={`StayCard_${id}`}
          ratioClass="aspect-w-4 aspect-h-3 "
          galleryImgs={combinedArray}
          // href={href}
        />
        {formikValues && (
          <BtnLikeIcon isLiked={pet} className="absolute right-3 top-3 z-[1]" />
        )}
      </div>
    );
  };

  const renderContent = () => {
    const addressArray = typeof Address === "string" && Address.trim()
    ? Address.split(",")
        .map((item: any) => item.trim().replace(/\b\d{6}\b/, "").trim()) // Remove pincode if part of the string
        .filter((part: any) => part) // Remove empty strings
    : [];
    return (
      <div className={size === "default" ? "p-4 space-y-4" : "p-3 space-y-2"}>
      <div className="space-y-2">
       
        <div className="flex items-center space-x-2">
          <h2
            className={` font-medium capitalize ${
              size === "default" ? "lg:text-lg" : "text-base"
            }`}
          >
            <span className="line-clamp-1">{title}</span>
          </h2>
        </div>
        <span className="lg:text-xs text-xs text-neutral-500 dark:text-neutral-400 w-full block text-truncate text-nowrap">
          {category}<span style={{ margin: "0 5px", fontWeight: "bold" }}>•</span>
          {type}<span style={{ margin: "0 5px", fontWeight: "bold" }}>•</span>
          {addressArray?.slice(-1).join(",")}
        </span>
        {/* <div className="flex items-center text-neutral-500 dark:text-neutral-400 text-sm space-x-2">
          {size === "default" && (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5 flex-shrink-0"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
              />
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
              />
            </svg>
          )}
          <span className="line-clamp-1">{address}</span>
        </div> */}
      </div>
      {/* <div className="w-14 border-b border-neutral-100 dark:border-neutral-800"></div> */}
      <div className="flex justify-between items-center">
        <span
          className="text-base font-semibold"
          style={{ fontFamily: "Roboto" }}
        >
          {Number(price).toLocaleString("en-IN", {
            style: "currency",
            currency: "INR",
            minimumFractionDigits: 0,
          })}
          {size === "default" && (
            <span className="text-sm text-neutral-500 dark:text-neutral-400 font-normal">
              /night  
            </span>
          )}
        </span>
        {/* {!!count && <StartRating reviewCount={count} point={avgRating} />} */}
      </div>
    </div>
    );
  };

  return (
    <div
      className={`nc-StayCard group relative bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-2xl overflow-hidden will-change-transform hover:shadow-xl transition-shadow ${className}`}
      data-nc-id="StayCard"
    >
      {renderSliderGallery()}
      {renderContent()}
    </div>
  );
};

export default StayCard;
