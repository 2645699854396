import React, { FC, useEffect, useState, useRef } from "react";
import Input from "shared/Input/Input";
import CommonLayout from "./CommonLayout";
import FormItem from "./FormItem";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import axios from "axios";
import { API_URL } from "../../api/config";
import { toast } from "react-toastify";
export interface PageAddListing1Props {
  formik: any;
  setActiveStep: any;
  setIsOtpSent: any;
  isOtpSent: any;
}

const PageAddListing1: FC<PageAddListing1Props> = ({
  formik,
  setActiveStep,
  setIsOtpSent,
  isOtpSent,
}) => {
  const [otp, setOtp] = useState(Array(4).fill(""));
  const [otpVerified, setOtpVerified] = useState(false);
  const [resendDisabled, setResendDisabled] = useState(false);
  const [countdown, setCountdown] = useState(0);

  const firstInputRef = useRef<HTMLInputElement>(null);
  const timerRef = useRef<NodeJS.Timeout | null>(null);

  // Clear the timer on unmount
  useEffect(() => {
    return () => {
      if (timerRef.current) clearTimeout(timerRef.current);
    };
  }, []);

  useEffect(() => {
    // Autofocus the first OTP input when OTP is sent
    if (isOtpSent && firstInputRef.current) {
      firstInputRef.current.focus();
    }
  }, [isOtpSent]);

  const handleOtpChange = (value: any, index: any) => {
    if (!/^\d*$/.test(value)) return;

    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    // Update Formik's otp value with the joined OTP digits
    formik.setFieldValue("otp", newOtp.join(""));
    // Focus the next input
    if (value && index < otp.length - 1) {
      document.getElementById(`otp-input-${index + 1}`)?.focus();
    }
  };

  const handleGetOtp = async () => {
    const propertyPhoneNumber = formik.values.propertyPhoneNumber;

    if (!propertyPhoneNumber) {
      toast.error("Please enter a valid phone number.");
      return;
    }

    try {
      setOtp(Array(4).fill(""));

      const response = await axios.post(`${API_URL}/property/request-otp`, {
        propertyPhoneNumber,
      });
      if (response.data.error === false) {
        toast.success("OTP sent successfully.");
        setIsOtpSent(true);

        setResendDisabled(true);
        setCountdown(120); // Set timer to 120 seconds

        // Set focus to the first OTP input
        if (firstInputRef.current) {
          firstInputRef.current.focus();
        }
        // Start countdown timer
        timerRef.current = setInterval(() => {
          setCountdown((prev) => {
            if (prev === 1) {
              clearInterval(timerRef.current!);
              setResendDisabled(false);
              return 0;
            }
            return prev - 1;
          });
        }, 1000);
      }
    } catch (error: any) {
      toast.error(error.response?.data?.message || "Failed to send OTP.");
    }
  };

  const formatTime = (seconds: number) => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${mins}:${secs < 10 ? `0${secs}` : secs}`;
  };

  const handleVerifyOtp = async () => {
    const propertyPhoneNumber = formik.values.propertyPhoneNumber;
    const otpValue = otp.join("");

    if (otpValue.length !== 4) {
      toast.error("Please enter the complete OTP.");
      return;
    }

    try {
      const response = await axios.post(`${API_URL}/property/request-otp`, {
        propertyPhoneNumber,
        otp: otpValue,
      });

      if (response.data.error === true) {
        toast.error(response.data.message);
      } else {
        toast.success("Phone number verified successfully.");
        setOtp(Array(4).fill(""));
        await setOtpVerified(true);

        setActiveStep((prevActiveStep: any) => prevActiveStep + 1);
      }
    } catch (error: any) {
      toast.error(error.response?.data?.message || "OTP verification failed.");
    }
  };

  return (
    <CommonLayout
      index="1"
      backtHref="/add-listing-1"
      nextHref="/add-listing-2"
    >
      <>
        <h2 className="text-2xl font-semibold">Choosing listing categories</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        <div className="space-y-8">
          <FormItem>
            <Input
              className="!sm:w-full md:w-4/6 text-neutral-900"
              placeholder="Name your property"
              {...formik.getFieldProps("title")}
            />
            {formik.errors && formik.errors.title && (
              <span className="text-red-500 text-sm">
                {formik.errors.title}
              </span>
            )}
          </FormItem>

          <FormItem>
            <div className="flex">
              <Input
                type="tel"
                className="sm:!w-4/6 !text-neutral-900"
                placeholder="Assign phone number"
                {...formik.getFieldProps("propertyPhoneNumber")}
                onInput={(e) => {
                  const input = e.target as HTMLInputElement;
                  input.value = input.value.replace(/\D/g, "");
                }}
                maxLength={10}
              />

              {otpVerified === true ? (
                ""
              ) : (
                <ButtonPrimary
                  className={`rounded-none ml-2`}
                  onClick={handleGetOtp}
                >
                  {/* {isOtpSent ? "Resend OTP" : "Get OTP"} */}
                  {isOtpSent
                    ? resendDisabled
                      ? `Resend OTP (${formatTime(countdown)})`
                      : "Resend OTP"
                    : "Get OTP"}
                </ButtonPrimary>
              )}
            </div>

            {formik.errors && formik.errors.propertyPhoneNumber && (
              <span className="text-red-500 text-sm">
                {formik.errors.propertyPhoneNumber}
              </span>
            )}
          </FormItem>
          {isOtpSent && otpVerified === false ? (
            <div className="flex space-x-2  mt-2 w-3/6">
              {otp.map((digit, index) => (
                <>
                  <Input
                    name="otp"
                    key={index}
                    id={`otp-input-${index}`}
                    type="tel"
                    ref={index === 0 ? firstInputRef : undefined}
                    className="!w-14 h-14 text-center text-neutral-600"
                    value={digit}
                    onChange={(e) => handleOtpChange(e.target.value, index)}
                    maxLength={1}
                  />
                </>
              ))}
            </div>
          ) : (
            ""
          )}

          {otpVerified ? (
            ""
          ) : (
            <ButtonPrimary
              onClick={handleVerifyOtp}
              disabled={!isOtpSent}
              className={`${isOtpSent ? "visible" : "invisible"} rounded-none`}
            >
              Verify OTP
            </ButtonPrimary>
          )}
        </div>
      </>
    </CommonLayout>
  );
};

export default PageAddListing1;
