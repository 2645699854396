import React, { FC, useState } from "react";
import CommonLayout from "./CommonLayout";
import camera from "../../images/PropHighlights/camera.svg";
import cigeratte from "../../images/PropHighlights/cigeratte.svg";
import glasscheers from "../../images/PropHighlights/glasscheers.svg";
import couple from "../../images/PropHighlights/couple.svg";
import pet from "../../images/PropHighlights/pet.svg";
import Textarea from "shared/Textarea/Textarea";
export interface PageAddListing6Props {
  formik: any;
}

const PageAddListing6: FC<PageAddListing6Props> = ({ formik }) => {
  const handleInputChange = (val: any) => {
    formik.setFieldValue("place_descriptions", val.target.value);
  };

  const handleTogglePropertyHighlights = (option: any) => {
    const currentPropertyHighlights = formik.values.propertyHighlights;
    if (currentPropertyHighlights.includes(option.title)) {
      formik.setFieldValue(
        "propertyHighlights",
        currentPropertyHighlights.filter((item: any) => item !== option.title)
      );
    } else {
      formik.setFieldValue("propertyHighlights", [
        ...currentPropertyHighlights,
        option.title,
      ]);
    }
  };

  const handleToggleTypeOfLocation = (option: any) => {
    const currentTypeOfLocation = formik.values.typeOfLocation;

    if (currentTypeOfLocation?.includes(option)) {
      formik.setFieldValue(
        "typeOfLocation",
        currentTypeOfLocation.filter((item: any) => item !== option)
      );
    } else {
      formik.setFieldValue("typeOfLocation", [
        ...currentTypeOfLocation,
        option,
      ]);
    }
  };

  const propertyHighlights = [
    {
      title: "Couple Friendly",
      icon: couple,
    },
    {
      title: "Pet Friendly",
      icon: pet,
    },
    {
      title: "Smoking allowed",
      icon: cigeratte,
    },
    {
      title: "Party Friendly",
      icon: glasscheers,
    },
    {
      title: "Shoot Friendly",
      icon: camera,
    },
  ];
  const typesOfLocation = [
    "On the beach",
    "In the hills",
    "Downtown",
    "Inside a forest",
    "Riverside",
    "Urban area",
    "Rural area",
    "Near wildlife",
    "on the lake",
  ];
  return (
    <CommonLayout
      index="06"
      backtHref="/add-listing-5"
      nextHref="/add-listing-7"
    >
      <>
        <div>
          <h2 className="text-2xl font-semibold">Property Highlights </h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            Guests must agree to your house rules before they book.
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        <div className="space-y-8">
          <div className="flex flex-row flex-wrap ">
            {propertyHighlights.map((option) => (
              <button
                key={option.title}
                type="button"
                onClick={() => handleTogglePropertyHighlights(option)}
                className={`flex items-center justify-center px-4 py-2 border rounded-full text-sm font-medium mt-2 mr-2 ${
                  formik.values.propertyHighlights.includes(option.title)
                    ? "gradient-border"
                    : "bg-white text-gray-700 border-gray-300"
                } hover:bg-gray-100`}
              >
                <span>
                  <img src={option.icon} alt="icon" className="mr-2" />
                </span>
                {option.title}
              </button>
            ))}
          </div>
          {formik.errors && formik.errors.propertyHighlights && (
            <span className="text-red-500 text-sm">
              {formik.errors.propertyHighlights}
            </span>
          )}

          {/* TYPE OF LOCATION */}
          <div>
            <h2 className="text-lg font-semibold">Type Of Location </h2>
            <div className="flex flex-row flex-wrap mt-2 ">
              {typesOfLocation.map((option) => (
                <button
                  key={option}
                  type="button"
                  onClick={() => handleToggleTypeOfLocation(option)}
                  className={`flex items-center justify-center px-4 py-2 border rounded-full text-sm font-medium mt-2 mr-2 ${
                    formik.values.typeOfLocation?.includes(option)
                      ? "gradient-border"
                      : "bg-white text-gray-700 border-gray-300"
                  } hover:bg-gray-100`}
                >
                  {option}
                </button>
              ))}
            </div>
            {formik.errors && formik.errors.typeOfLocation && (
              <span className="text-red-500 text-sm">
                {formik.errors.typeOfLocation}
              </span>
            )}
          </div>

          {/* PLACE DESCRIPTION */}
          <div>
            <h2 className="text-lg font-semibold">
              Your place description for client
            </h2>
            <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
              Mention the best features of your accommodation, any special
              amenities like fast Wi-Fi or parking, as well as things you like
              about the neighborhood.
            </span>
          </div>
          {formik.errors && formik.errors.place_descriptions && (
            <span className="text-red-500 text-sm">
              {formik.errors.place_descriptions}
            </span>
          )}
          <Textarea
            value={formik.values.place_descriptions}
            handleChange={handleInputChange}
            placeholder="..."
            rows={14}
          />
          <div className=" border-b border-neutral-200 dark:border-neutral-700"></div>
        </div>
      </>
    </CommonLayout>
  );
};

export default PageAddListing6;
