import { FC } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { API_URL } from "../../api/config";
import { Helmet } from "react-helmet";
import Input from "shared/Input/Input";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";

export interface PageLoginProps {
  className?: string;
}

const PageLogin: FC<PageLoginProps> = ({ className = "" }) => {
  const navigate = useNavigate();
  const [isLoading, setisLoading] = useState(false);

  const queryParams = new URLSearchParams(window.location.search);

  const handleGetOtp = async (values: any) => {
    setisLoading(true);
    const propId = queryParams.get("propID");

    try {
      const response = await axios.post(`${API_URL}/owner/request-otp`, values);
      const message = response.data.message;

      if (response.status === 200) {
        toast.success(message);
        navigate(`/verify?phoneNumber=${values.phoneNumber}`);
      }
    } catch (error) {
      toast.error("Error during login");
      console.error("Error during login:", error);
    }

    setisLoading(false);
  };
  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      phoneNumber: "",
    },
    validationSchema: Yup.object({
      phoneNumber: Yup.string()
        .matches(/^\d+$/, "Phone number can only contain numbers")
        .min(10, "Phone number must be at least 10 digits")
        .max(15, "Phone number must be no more than 15 digits")
        .required("Phone number is required"),
    }),
    onSubmit: handleGetOtp,
  });

  return (
    <div className={`nc-PageLogin ${className}`} data-nc-id="PageLogin">
      <Helmet>
        <title>Login / DirecStays</title>
      </Helmet>

      <div className="container my-20 lg:mb-8 relative text-center">
        <h2 className="my-8 flex items-center text-3xl leading-[115%] md:text-4xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Login to continue
        </h2>
        <div className="max-w-md mx-auto space-y-10">
          <form
            className="grid grid-cols-1 gap-6"
            onSubmit={validation.handleSubmit}
            method="post"
          >
            <label className="block">
              <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                Phone number
              </span>
              <Input
                name="phoneNumber"
                type="tel"
                className="mt-1 text-neutral-600"
                value={validation.values.phoneNumber}
                onChange={validation.handleChange}
                onInput={(e) => {
                  const input = e.target as HTMLInputElement;
                  input.value = input.value.replace(/\D/g, "");
                }}
                maxLength={10}
                pattern="\d*"
              />
              {validation.touched.phoneNumber &&
              validation.errors.phoneNumber ? (
                <span className="text-red-500 text-sm">
                  {validation.errors.phoneNumber}
                </span>
              ) : null}
            </label>

            <ButtonPrimary disabled={isLoading} type="submit">
              Get OTP
            </ButtonPrimary>
          </form>
        </div>
      </div>
    </div>
  );
};

export default PageLogin;
