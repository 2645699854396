import React, { FC, useState } from "react";
import CommonLayout from "./CommonLayout";
import Textarea from "shared/Textarea/Textarea";


export interface PageAddListing7Props {
  formik: any;
}

const PageAddListing7: FC<PageAddListing7Props
> = ({ formik }) => {
  const handleInputChange = (val: any) => {
    formik.setFieldValue("propertyRules", val.target.value);
  };

  return (
    <CommonLayout
      index="07"
      backtHref="/add-listing-6"
      nextHref="/add-listing-8"
    >
      <>
        <div>
          <h2 className="text-2xl font-semibold">Property Rules</h2>

        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* PROPERTY RULES */}

        {formik.errors && formik.errors.propertyRules && (
          <span className="text-red-500 text-sm">
            {formik.errors.propertyRules}
          </span>
        )}
        <Textarea
          value={formik.values.propertyRules}
          handleChange={handleInputChange}
          placeholder="..."
          rows={14}
        />
      </>
    </CommonLayout>
  );
};

export default PageAddListing7;
