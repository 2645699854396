import { FC, useState, useEffect ,useContext} from "react";
import { Helmet } from "react-helmet";
import Input from "shared/Input/Input";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { API_URL } from "../../api/config";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSearchParams } from "react-router-dom";
import { AuthContext } from "context/userContext";

export interface PageSignUpProps {
  className?: string;
}

const PageSignUp: FC<PageSignUpProps> = ({ className = "" }) => {
  const navigate = useNavigate();
  const [phoneNumber, setPhoneNumber] = useState<string | null>(null);
  const authContext = useContext(AuthContext);
  const { getAdminData } = authContext;
  const [isLoading, setisLoading] = useState(false);
  let [searchParams] = useSearchParams();
  useEffect(() => {
    var phone = searchParams?.get("phoneNumber");
    if (phone) {
      setPhoneNumber(phone);
    }
  }, []);

  const handleSignup = async (values: any) => {
    setisLoading(true);
    try {
      const response = await axios.post(`${API_URL}/owner/owner-signup`, {
        ...values,
        phoneNumber,
      });

      const text = response.data.message;

      if (response.data.error === false) {
        toast.success(text);
        localStorage.setItem("token", response.data.token);
        setTimeout(() => {
          navigate("/property");
        }, 1000);
        getAdminData();
      }
      if (response.data.error === true && !!response.data.result) {
        toast.error(response.data.result.msg);
      }

      if (response.data.error === true) {
        toast.error(text);
        navigate("/login");
      }
    } catch (error) {
      toast.error("Error during Signup");
      console.error("Error during Signup:", error);
    }
    setisLoading(false);
  };
  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      name: "",
      email: "",
      dateOfBirth: "",
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .min(2, "name should be of atleast 2 words")
        .required("Name should not be empty!"),
      email: Yup.string().email().required("Email should not be empty!"),

      dateOfBirth: Yup.date().required("Date of birth is required"),
    }),
    onSubmit: handleSignup,
  });

  return (
    <div className={`nc-PageSignUp  ${className}`} data-nc-id="PageSignUp">
      <Helmet>
        <title>Sign up || Booking React Template</title>
      </Helmet>
      <div className="container my-20 lg:mb-8 relative text-center">
        <h2 className="my-8 flex items-center text-3xl leading-[115%] md:text-4xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Help us Know You Better
        </h2>
        <p className="flex items-center justify-center mb-8 text-neutral-500">
          Enter your details below.
        </p>

        <div className="max-w-md mx-auto space-y-6 ">
          <form
            className="grid grid-cols-1 gap-6"
            onSubmit={validation.handleSubmit}
            method="post"
          >
            <label className="block">
              <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                Name
              </span>
              <Input
                name="name"
                type="text"
                className="mt-1 text-neutral-600"
                value={validation.values.name}
                onChange={validation.handleChange}
              />
              {validation.touched.name && validation.errors.name ? (
                <span className="text-red-500 text-sm">
                  {validation.errors.name}
                </span>
              ) : null}
            </label>
            <label className="block">
              <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                Email address
              </span>
              <Input
                name="email"
                type="email"
                className="mt-1 text-neutral-600"
                value={validation.values.email}
                onChange={validation.handleChange}
              />
              {validation.touched.email && validation.errors.email ? (
                <span className="text-red-500 text-sm">
                  {validation.errors.email}
                </span>
              ) : null}
            </label>

            <label className="block">
              <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                Date of Birth
              </span>
              <Input
                name="dateOfBirth"
                type="date"
                className="mt-1 text-neutral-600"
                value={validation.values.dateOfBirth}
                onChange={validation.handleChange}
              />
              {validation.touched.dateOfBirth &&
              validation.errors.dateOfBirth ? (
                <span className="text-red-500 text-sm">
                  {validation.errors.dateOfBirth}
                </span>
              ) : null}
            </label>

            <ButtonPrimary disabled={isLoading} type="submit">
              Proceed
            </ButtonPrimary>
          </form>
        </div>
      </div>
    </div>
  );
};

export default PageSignUp;
