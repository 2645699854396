import React, { FC } from "react";
import CommonLayout from "./CommonLayout";
// import barbar from "public/Amenities/barbar.svg";
// import fingerprint from "../../images/Amenities/fingerprint.svg";
// import monitor from "../../images/Amenities/monitor.svg";
// import cooking from "../../images/Amenities/cooking.svg";
// import Towel from "../../images/Amenities/Towel.svg";
// import towel2 from "../../images/Amenities/towel2.svg";
// import Butler from "../../images/Amenities/Butler.svg";
// import doublebed from "../../images/Amenities/doublebed.svg";
// import fridge from "../../images/Amenities/fridge.svg";
// import Hall from "../../images/Amenities/Hall.svg";
// import hanger from "../../images/Amenities/hanger.svg";
// import induction from "../../images/Amenities/induction.svg";
// import iron from "../../images/Amenities/iron.svg";
// import Kitchen from "../../images/Amenities/Kitchen.svg";
// import laundry from "../../images/Amenities/laundry.svg";
// import microwave from "../../images/Amenities/microwave.svg";
// import mineralwater from "../../images/Amenities/mineralwater.svg";
// import mosquitoRepellent from "../../images/Amenities/mosquitoRepellent.svg";
// import raindrops from "../../images/Amenities/raindrops.svg";
// import Shampoo from "../../images/Amenities/Shampoo.svg";
// import Shower from "../../images/Amenities/Shower.svg";
// import snowflake from "../../images/Amenities/snowflake.svg";
// import utensils from "../../images/Amenities/utensils.svg";
// import washingMachine from "../../images/Amenities/washingMachine.svg";
// import waterheater from "../../images/Amenities/waterheater.svg";
// import waterpurifier from "../../images/Amenities/waterpurifier.svg";
// import watertank from "../../images/Amenities/watertank.svg";
// import wifi from "../../images/Amenities/wifi.svg";
// import gasstove from "../../images/Amenities/gasstove.svg";
// import scenicviews from "../../images/Amenities/scenicviews.svg";
// import wardrobe from "../../images/Amenities/wardrobe.svg";
// import jetspray from "../../images/Amenities/jetspray.svg";
// import evcharging from "../../images/Amenities/ev charging.svg";



export interface PageAddListing4Props {
  formik: any;
}

const PageAddListing4: FC<PageAddListing4Props> = ({ formik }) => {
  const amenitiesList = [
    { "title": "24/7 Water Supply", "icon": "/Amenities/raindrops.svg" },
    { "title": "Balcony", "icon": "/Amenities/Hall.svg" },
    { "title": "Bedsheets", "icon": "/Amenities/doublebed.svg" },
    { "title": "Blankets", "icon": "/Amenities/Towel.svg" },
    { "title": "Clothes Hanger", "icon": "/Amenities/hanger.svg" },
    { "title": "EV Charging", "icon": "/Amenities/ev charging.svg" },
    { "title": "Fridge", "icon": "/Amenities/fridge.svg" },
    { "title": "Gas Stove", "icon": "/Amenities/gasstove.svg" },
    { "title": "HouseKeeping Service", "icon": "/Amenities/Butler.svg" },
    { "title": "Induction", "icon": "/Amenities/cooking.svg" },
    { "title": "Iron", "icon": "/Amenities/iron.svg" },
    { "title": "Jet Spray", "icon": "/Amenities/jetspray.svg" },
    { "title": "Laundry Service", "icon": "/Amenities/laundry.svg" },
    { "title": "Microwave", "icon": "/Amenities/microwave.svg" },
    { "title": "Mineral Water", "icon": "/Amenities/mineralwater.svg" },
    { "title": "Car Parking ", "icon": "/Amenities/car.svg" },
    { "title": "Mosquito repellent", "icon": "/Amenities/mosquitoRepellent.svg" },
    { "title": "Oven", "icon": "/Amenities/Kitchen.svg" },
    { "title": "Room Freshner", "icon": "/Amenities/snowflake.svg" },
    { "title": "Scenic Views", "icon": "/Amenities/scenicviews.svg" },
    { "title": "Self Checkin", "icon": "/Amenities/fingerprint.svg" },
    { "title": "Showers", "icon": "/Amenities/Shower.svg" },
    { "title": "Smart TV", "icon": "/Amenities/monitor.svg" },
    { "title": "Toiletries", "icon":"/Amenities/Shampoo.svg"},
    { "title": "Towels", "icon": "/Amenities/towel2.svg" },
    { "title": "Utensils", "icon": "/Amenities/utensils.svg" },
    { "title": "Wardrobe", "icon": "/Amenities/wardrobe.svg" },
    { "title": "Washing Machine", "icon": "/Amenities/washingMachine.svg" },
    { "title": "Water Filter", "icon": "/Amenities/waterpurifier.svg" },
    { "title": "Water Heater", "icon": "/Amenities/waterheater.svg" },
    { "title": "Water Storage Tank", "icon": "/Amenities/watertank.svg" },
    { "title": "Wifi", "icon": "/Amenities/wifi.svg" },

  ];
  
  
  


  const handleToggleAmenity = (amenity:any) => {
    const currentAmenities = formik.values.amenities;
    if (currentAmenities.includes(amenity.title)) {
      formik.setFieldValue(
        "amenities",
        currentAmenities.filter((item: any) => item !== amenity.title)
      );
    } else {
      formik.setFieldValue("amenities", [...currentAmenities, amenity.title]);
    }
  };

  return (
    <CommonLayout
      index="04"
      backtHref="/add-listing-3"
      nextHref="/add-listing-5"
    >
      <div>
        <h2 className="text-2xl font-semibold">Amenities </h2>
        <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
          Many customers have searched for accommodation based on amenities
          criteria
        </span>
      </div>
      <div className="w-14 border-b border-neutral-200 dark:border-neutral-700 my-8"></div>
      <div className="flex flex-row flex-wrap ">
        {amenitiesList.map((amenity) => (
          <button
            key={amenity.title}
            type="button"
            onClick={() => handleToggleAmenity(amenity)}
            className={`flex items-center justify-center px-4 py-2 rounded-full text-sm font-medium mt-2 mr-2
     
    ${
      formik.values.amenities.includes(amenity.title)
        ? "gradient-border"
        : "border border-gray-300 hover:bg-gray-100"
    } 
  `}
          >
            <span className="flex items-center">
              <img src={amenity.icon} alt="icon" className="mr-2" />
              {amenity.title}
            </span>
          </button>
        ))}
      </div>
    </CommonLayout>
  );
};

export default PageAddListing4;
