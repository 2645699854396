import React from "react";
import { Link } from "react-router-dom";
import LogoSvgLight from "./LogoSvgLight";
import LogoSvg from "./LogoSvg";
import dsLogo from "../../images/logos/ds_logo1.png";

export interface LogoProps {
  img?: string;
  imgLight?: string;
  className?: string;
}

const Logo: React.FC<LogoProps> = ({
  className = "lg:w-[130px] w-28 h-auto sm:pl-4 lg:pl-0 z-10",
}) => {
  return (
      <Link to="/property" className={`ttnc-logo inline-block ${className}`}>
        <img src={dsLogo} alt="direcStays logo" />
      </Link>
  );
};

export default Logo;
