import React, { FC, useState } from "react";
import CommonLayout from "./CommonLayout";
import FormItem from "./FormItem";

export interface PageAddListing8Props {
  formik: any;
}

const PageAddListing8: FC<PageAddListing8Props> = ({ formik }) => {
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    formik.setFieldValue("price", e.target.value);
  };

  return (
    <CommonLayout
      index="08"
      backtHref="/add-listing-7"
      nextHref="/add-listing-9"
    >
      <>
        <div>
          <h2 className="text-2xl font-semibold">Price your space</h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            The host's revenue is directly dependent on the setting of rates and
            regulations on the number of guests, the number of nights, and the
            cancellation policy.
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* FORM */}
        <div className="">
          <FormItem>
            <div className="relative flex row">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <span
                  className="text-gray-500"
                  style={{ fontFamily: "Roboto,sans-serif" }}
                >
                  ₹
                </span>
              </div>
              <input
                value={formik.values.price}
                onChange={handleInputChange}
                type="text"
                // className={`block w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 rounded-2xl text-sm font-normal h-11 px-4 py-3 !pl-8 !pr-10`}
                className={`block w-full border-neutral-200 bg-white dark:border-neutral-700 dark:bg-neutral-900 rounded-2xl text-sm font-normal h-11 px-4 py-3 !pl-8 !pr-10 focus-ring`}

/>
            </div>
          </FormItem>
          {formik.errors && formik.errors.price && (
            <span className="text-red-500 text-sm">{formik.errors.price}</span>
          )}
        </div>
      </>
    </CommonLayout>
  );
};

export default PageAddListing8;
